import { makeStyles } from "@mui/styles";
import palette from "../../utils/Theme/palette";

const useStyles = makeStyles({
  homeContainer: {
    padding: "39px 54px",
  },
  fashionCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #DCE6F9 0%, #ADCDFE 100%) !important",
    paddingTop: "33px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "317px !important",
    cursor: "pointer",
  },
  fashionImages: {
    textAlign: "center",
  },
  categoryTypo: {
    color: "#3A1500",
    textAlign: "center",
    marginTop: "24px !important",
  },
  fashionImage2: {
    marginLeft: "-30px",
  },
  electronicsCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #DCF9F2 0%, #ADEFFE 100%) !important",
    paddingTop: "23px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "177px !important",
    cursor: "pointer",
  },
  electronicsImages: {
    textAlign: "center",
  },
  electronicsImage: {
    verticalAlign: "unset",
  },
  tvImage: {
    marginLeft: "-20px",
  },
  mobileImage: {
    marginLeft: "-70px",
  },
  tabImage: {
    marginLeft: "2px",
  },
  typoContainer: {
    display: "flex",
  },
  electronicsTypo: {
    margin: "auto !important",
    marginLeft: "0px !important",
  },

  groceryCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #F9E3DC 0%, #FECAAD 100%) !important",
    paddingTop: "33px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "210px !important",
    cursor: "pointer",
  },
  groceryImages: {
    textAlign: "center",
  },
  groceryImage: {
    verticalAlign: "unset",
  },
  groceryTypo: {
    margin: "auto !important",
    marginLeft: "0px !important",
  },

  foodCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #FFE1C1 0%, #FED092 100%) !important",
    paddingTop: "33px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "288px !important",
    cursor: "pointer",
  },
  foodImages: {
    textAlign: "center",
  },

  healthCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #CDFFD2 0%, #C2DDD8 100%) !important",
    paddingTop: "33px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "210px !important",
    cursor: "pointer",
  },
  healthImages: {
    textAlign: "center",
  },
  healthImage2: {
    // marginLeft: '-30px'
  },

  healthTypo: {
    marginTop: "30px !important",
  },

  homeCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #F9F2E2 0%, #D9C9A8 100%) !important",
    paddingTop: "33px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "317px !important",
    cursor: "pointer",
  },
  homeImages: {
    textAlign: "center",
    height: "70%",
  },
  homeImage1: {
    // marginLeft: '-30px'
    width: "200px",
  },
  homeImage2: {
    // marginLeft: '-30px'
    // width: "35%",
  },

  bpcCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #F7DCF9 0%, #C7ADFE 100%) !important",
    paddingTop: "15px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "177px !important",
    cursor: "pointer",
  },
  bpcImages: {
    textAlign: "center",
  },
  bpcImage: {
    verticalAlign: "unset",
  },
  bpcTypo: {
    margin: "auto !important",
    marginLeft: "0px !important",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  agricultureCategory: {
    borderRadius: "8px !important",
    background: "linear-gradient(180deg, #E7F9DC 0%, #BFF2C1 100%) !important",
    paddingTop: "33px !important",
    paddingBottom: "23px !important",
    boxShadow: "none !important",
    height: "288px !important",
    cursor: "pointer",
  },
  agricultureImages: {
    textAlign: "center",
  },
  agricultureImage: {
    height: "230px",
  },
  agricultureCategoryTypo: {
    color: "#3A1500",
    textAlign: "center",
    marginTop: "-10px !important",
  },
  viewAllLessButtonContainer: {
    textAlign: "center",
    marginTop: "16px !important",
  },
});

export default useStyles;
